<template>
    <div class="nav">
      <el-menu ref="elNanMenu" :default-active="activeIndex" class="el-menu" mode="horizontal"
        background-color="#354053" text-color="#fff" active-text-color="#fff">
        <el-menu-item :key="key" v-for="(val,key) in menuData" :index="key+''" @click.middle="backOpen(val)" @click='openTo(val)'>{{val.label}}</el-menu-item>
      </el-menu>
      <dialog-box ref="setUserInfo" :dialogWidth="'25%'" :showClose="false" :dialogShow="dialogShow" title="补充信息" componentName="setUserInfo" :buttonData="[{label:'确认',action:'action',size:'mini',type:'primary'}]" @action="action"></dialog-box>
    </div>
</template>
<script>

import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
// import { menu } from './menu.js'
export default {
  name: 'IndexNav',
  components: Component.components,
  data: function () {
    return {
      menuData: {},
      listData: [],
      activeIndex: null,
      dialogShow: false
    }
  },
  created () {
    request('/api/system/user/getUserInfoAndMenuMessage', 'GET', {}).then((response) => {
      localStorage.setItem('userName', response.data.username)
      localStorage.setItem('userId', response.data.id)

      try {
        const rolesstr = []
        for (const d of response.data.sysRoles) {
          rolesstr.push(d.roleCode)
        }
        localStorage.setItem('roleCode', rolesstr.join(','))
      } catch (error) {

      }

      sessionStorage.setItem('businessScope', response.data.businessScope)
      sessionStorage.removeItem('redirect')
      this.$store.commit('setLoginInfo', response.data)
      this.$store.commit('setMenuData', response.data.permissions)
      this.menuData = response.data.permissions
      if (response.data.name === null || response.data.deptName === null || response.data.mail === null) {
        if (response.data.userType === 1) {
          this.dialogShow = true
        }
      }
      sessionStorage.setItem('enterpriseCode', response.data.enterpriseCode)
      response.data.permissions.forEach((element, index) => {
        if (this.$route.params.frameName === element.alias) {
          this.activeIndex = index + ''
        }
      })
    })
    /* localStorage.setItem('userName', menu.data.username)
    localStorage.setItem('userId', menu.data.id)
    sessionStorage.removeItem('redirect')
    this.$store.commit('setLoginInfo', menu.data)
    this.$store.commit('setMenuData', menu.data.permissions)
    this.menuData = menu.data.permissions
    sessionStorage.setItem('enterpriseCode', menu.data.enterpriseCode)
    menu.data.permissions.forEach((element, index) => {
      if (this.$route.params.frameName === element.alias) {
        this.activeIndex = index + ''
      }
    }) */
  },
  mounted: function () {
  },
  methods: {
    setDefault () {
      if (this.$refs.elNanMenu.$el.querySelector('.is-active')) {
        this.$refs.elNanMenu.$el.querySelector('.is-active').setAttribute('class', 'el-menu-item')
      }
    },
    action: function () {
      this.$refs.setUserInfo.$refs.content.$refs.userInfo.validate((valid) => {
        if (valid) {
          var userdata = this.$refs.setUserInfo.$refs.content.formData
          userdata.id = localStorage.getItem('userId')
          request('/api/system/user/updateUserDept', 'post', userdata).then((response) => {
            this.dialogShow = false
          })
        } else {
          return false
        }
      })
    },
    backOpen (data) {
      const menudata = this.getNoOneMenu(data)
      window.open(`/${data.alias}/${menudata.alias}`)
    },
    openTo: function (data) {
      const menudata = this.getNoOneMenu(data)
      if (window.event.ctrlKey) {
        window.open(`/${data.alias}/${menudata.alias}`)
        return false
      }
      data.defaultSelectId = menudata.id
      if (data.openWay === 1) {
        this.$store.commit('setSideMenuData', data)
        this.$router.push({ name: data.openView, params: { frameName: data.alias, viewName: menudata.alias } })
      }
      if (data.type === 'InWindow') {
        this.$router.push({ name: data.url, params: { id: 'new' } })
      }
      if (data.openWay === 4) {
        this.$store.commit('setSideMenuData', data)
        this.$router.push({ name: 'frame', params: { frameName: data.alias, viewName: data.openView }, query: { ViewId: menudata.alias } })
      }
      if (data.openWay === 2) {
        window.open(data.url)
      }
    },
    getNoOneMenu (data) {
      if (data.children.length > 0) {
        return this.getNoOneMenu(data.children[0])
      } else {
        return data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.nav{
    background: #FFFFFF;
    position: relative;
    margin-left: 10px;
    .el-menu-item{
      height: 65px;
      line-height: 64px;
      font-size: 16px;
    }
    .is-active{
      border-bottom: 0px solid #354053 !important;
      background: #24303F !important;
    }
    .button{
        display: flex;
        margin: 0;
        li{
            display: block;
            padding: 0 10px;
            padding-top:5px;
            padding-bottom:5px;
            border-bottom: 2px solid #fff;
            cursor: pointer;
            font-size: 14px;
            .buttonList{
                display: none;
                position: absolute;
                background: #fff;
                padding: 5px 10px;
                border: 1px solid #ccc;
                .list{
                    margin: 5px 0;
                    &:hover{
                        color: rgb(196, 51, 51);
                    }
                }
            }
            &:hover{
                border-bottom: 2px #b01f24 solid;
                .buttonList{
                    display: block;
                }
            }
        }
    }
}
</style>
