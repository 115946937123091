<template>
  <div class="header">
    <div class="banner" @click="goIndex">
      <img class="icon" src="https://oastatic.sugon.com/OA/srm/srm2.png">
      <div class="text">供需协同平台</div>
    </div>
    <index-nav ref="navbutton"></index-nav>
    <!-- <div class="userInfo" @click="logout">
      {{loginName}}
    </div> -->
    <div class="userInfo">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{loginName}}<i class="el-icon-user-solid el-icon--right"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu class="userDown">
            <el-dropdown-item @click="showInfo" icon="el-icon-s-custom">个人信息</el-dropdown-item>
            <el-dropdown-item @click="changePassword" icon="el-icon-lock">修改密码</el-dropdown-item>
            <el-dropdown-item @click="logout" icon="el-icon-back">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <dialog-box ref="dialogbox" :dialogShow="dialogShow" componentName="ChangePassword"
        @handleClose='handleClose' title="修改密码"
        :buttonData="buttonData" @action="action" ></dialog-box>
    <dialog-box ref="dialogbox2" :dialogShow="dialogShow2" componentName="ChangePassword"
         title="修改密码" :showClose="false"
        :buttonData="[{ label: '确认', action: 'action', size: 'mini', type: 'primary' }]" @action="action1" ></dialog-box>
    <dialog-box ref="dialogbox1" :dialogShow="dialogShow1" componentName="PsnInfo"
        @handleClose='handleClose' title="个人信息"
        :buttonData="buttonData" @action="handleClose" ></dialog-box>
  </div>
</template>

<script>
import IndexNav from './IndexNav.vue'
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
export default {
  components: { ...Component.components, IndexNav },
  name: 'IndexHeader',
  data: function () {
    return {
      dialogShow: false,
      dialogShow1: false,
      dialogShow2: false,
      tempToken: '',
      loginName: localStorage.getItem('userName'),
      buttonData: [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    }
  },
  created () {
    this.tempToken = localStorage.getItem('token')
    setInterval(() => {
      if ((localStorage.getItem('token') === '' || !localStorage.getItem('token')) &&
      (this.$route.path.indexOf('/Login') < 0 && this.$route.path.indexOf('/login') < 0 &&
      this.$route.path.indexOf('/Register') < 0 && this.$route.path.indexOf('/PasswordReset') < 0)) {
        request('/api/system/user/getUserInfoAndMenuMessage', 'GET', {}).then()
      } else {
        if ((localStorage.getItem('token') !== '' && localStorage.getItem('token')) && this.$route.path.indexOf('/login') >= 0) {
          if (sessionStorage.getItem('redirect') && sessionStorage.getItem('redirect').indexOf('/') >= 0) {
            location.href = sessionStorage.getItem('redirect')
          } else {
            location.href = '/index'
          }
        } else if (this.tempToken !== localStorage.getItem('token')) {
          window.location.reload()
        }
      }
    }, 1000)
  },
  watch: {
    '$store.state.loginInfo': function (val) {
      this.loginName = val.username
    }
  },
  methods: {
    showInfo () {
      this.dialogShow1 = true
    },
    changePassword () {
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
      this.dialogShow1 = false
    },
    action1 () {
      const data = { ...this.$refs.dialogbox2.$refs.content.formData }
      let pass = false
      this.$refs.dialogbox2.$refs.content.$refs.form.validate((valid) => {
        pass = valid
      })
      if (!pass) {
        return false
      }
      delete data.verifyPassword
      request('/api/system/user/updatePassword', 'get', data).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.logout()
          this.dialogShow2 = false
        }
      })
    },
    action () {
      const data = { ...this.$refs.dialogbox.$refs.content.formData }
      let pass = false
      this.$refs.dialogbox.$refs.content.$refs.form.validate((valid) => {
        pass = valid
      })
      if (!pass) {
        return false
      }
      delete data.verifyPassword
      request('/api/system/user/updatePassword', 'get', data).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.logout()
          this.dialogShow = false
        }
      })
    },
    goIndex () {
      this.$router.push({ name: 'ShowBox' })
      this.$store.commit('setSideMenuData', {})
      this.$refs.navbutton.setDefault()
    },
    logout: function () {
      sessionStorage.setItem('redirect', '/index')
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('userName')
      sessionStorage.removeItem('enterpriseCode')
      window.location.reload()
    }
  }
}
</script>

<style scoped lang="scss">
.header{
  height: 64px;
  background-color: #05275d;
  display: flex;
  align-items: center;

  // 顶部导航样式修改
  :deep(.el-menu.el-menu--horizontal){
    background-color: #05275d !important;
  }
  :deep(.el-menu--horizontal .el-menu-item){
    background-color: #05275d !important;
    border-bottom: 0 !important;
    border-bottom-color: #05275d !important;
  }
  :deep(.el-menu--horizontal .el-menu-item.is-active){
    background-color: #3685ff !important
  }
  :deep(.el-menu--horizontal .el-menu-item:hover){
    background-color: #3685ff !important
  }

  .menu{
    background: url('../assets/image/index/menu1.png');
    width: 30px;
    height: 30px;
    position: absolute;
    right: 200px;
  }
  .banner{
    img{
      width: 27px;
      height: 27px;
    }
    cursor: pointer;
    margin-left: 20px ;
    display: flex;
    color: #fff;
    font-size: 18px;
    width: 200px;
    flex-grow: 0;
    align-items: center;
    height: 100%;
    // background: #354053;
    .text{
       font-size: 18px;
       margin-left: 10px;
    }
  }
  .info{
    margin-left: 7%;
    color: #fff;
  }
  .userInfo{
    color: #949DA1;
    justify-self: end;
    font-size: 18px;
    position: absolute;
    right: 60px;
    cursor: pointer;
    :deep(.el-dropdown){
      color: #949DA1;
      font-size: 18px;
    }
  }
  .el-dropdown-menu__item{
    font-size: 16px;
  }
}
.userDown{
  .el-dropdown-menu__item{
    line-height: 34px;
    font-size: 14px;
  }
}
</style>
